import { format } from "date-fns"
import { Link } from "gatsby"
import React, { useState } from "react"
import { BiCalendar, BiFolderOpen, BiPurchaseTag } from "react-icons/bi"
import ReactMarkdown from "react-markdown"
import defaultEventPicture from "../../../../images/defaultEventPicture.jpg"
import defaultUserProfile from "../../../../images/defaultUserProfile.png"
import "../../../../styles/blogStyle/style.css"
import "../../../common/i18n"

import { useTranslation } from "react-i18next"
import { graphql, useStaticQuery } from "gatsby"

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const search = " "
const replaceWith = "-"

const ArticleContent = ({ articleData, tagsData }) => {
  const allStrapiArticles = useStaticQuery(graphql`
    query {
      articles: allStrapiArticles {
        edges {
          node {
            published_at
            roArticle
            title
            slug
            feature_image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const [articles, setArticles] = useState(
    allStrapiArticles.articles.edges.sort((a, b) => {
      return new Date(b.node.published_at) - new Date(a.node.published_at)
    })
  )

  const articlePostion = articles.findIndex(
    article => article.node.title === articleData.title
  )

  const searchForTag = tag => {
    if (window) {
      localStorage.setItem("savedBlogTags", `["${tag}"]`)
    }
  }

  return (
    <section className="blog-details-area ptb-100">
      <div style={{ marginTop: "105px" }} />
      <div className="container">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-8 col-md-12">
            <div className="blog-details-desc ">
              <h1 style={{ fontSize: "3rem" }}>{articleData.title}</h1>
            </div>
          </div>
        </div>
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-8 col-md-12">
            <div className="blog-details-desc">
              <div className="article-image">
                <img
                  src={
                    articleData.feature_image
                      ? articleData.feature_image.childImageSharp.fluid.src
                      : defaultEventPicture
                  }
                  alt="bb"
                />
              </div>

              <div className="article-content">
                <div className="entry-meta">
                  <ul
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <li>
                      <i>
                        <BiFolderOpen />
                      </i>
                      <span>Tag-uri</span>
                      {articleData.article_tags.map((tag, i) => (
                        <Link
                          key={i}
                          to="/article"
                          onClick={() => searchForTag(tag.name)}
                        >
                          {capitalizeFirstLetter(tag.name) + " "}
                        </Link>
                      ))}
                    </li>

                    <li>
                      <i>
                        <BiCalendar />
                      </i>
                      <span>Ultima Actualizare</span>
                      {format(new Date(articleData.published_at), "dd/MM/yyyy")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <ReactMarkdown
                  className="mkdown"
                  children={articleData.markdown}
                />
              </div>
              <div className="article-footer">
                <div className="article-tags">
                  <span>
                    <i>
                      <BiPurchaseTag />
                    </i>
                    <span>
                      <h1> Alte Tag-uri</h1>
                    </span>
                  </span>
                  <div className="article-tags">
                    {tagsData
                      ?.sort(() => 0.5 - Math.random())
                      .map((tag, i) => {
                        let j = 0
                        if (j < 2) {
                          if (
                            !articleData.article_tags.some(e => {
                              return e.name === tag.node.name
                            })
                          ) {
                            j++
                            return (
                              <Link
                                key={i}
                                to="/article"
                                onClick={() => searchForTag(tag.node.name)}
                              >
                                {tag.node.name}
                              </Link>
                            )
                          }
                        }
                      })}
                  </div>
                </div>
              </div>

              <div className="article-author">
                <div className="author-profile-header"></div>
                <div className="author-profile">
                  <div className="author-profile-title">
                    <img
                      src={
                        articleData.article_author.avatar
                          ? articleData.article_author.avatar.childImageSharp
                              .fluid.src
                          : defaultUserProfile
                      }
                      className="shadow-sm"
                      alt="uu"
                    />
                    <h4>{articleData.article_author.name}</h4>
                    <span className="d-block">
                      {articleData.article_author.shortDescription}
                    </span>
                  </div>
                </div>
              </div>

              <div className="tracer-post-navigation">
                <div className="prev-link-wrapper">
                  {articles[articlePostion + 1] && (
                    <div className="info-prev-link-wrapper">
                      <Link
                        to={`/article/${
                          articles[articlePostion + 1].node.slug
                        }`}
                      >
                        <span className="image-prev">
                          <img
                            src={
                              articles[articlePostion + 1].node.feature_image
                                ? articles[articlePostion + 1].node
                                    .feature_image.childImageSharp.fluid.src
                                : defaultEventPicture
                            }
                            alt="bb"
                          />
                          <span className="post-nav-title">Prev</span>
                        </span>

                        <span className="prev-link-info-wrapper">
                          <span className="prev-title">
                            {articles[articlePostion + 1].node.title}
                          </span>
                          <span className="meta-wrapper">
                            <span className="date-post">
                              {format(
                                new Date(
                                  articles[articlePostion + 1].node.published_at
                                ),
                                "dd MMM yyyy"
                              )}
                            </span>
                          </span>
                        </span>
                      </Link>
                    </div>
                  )}
                </div>

                <div className="next-link-wrapper">
                  {articles[articlePostion - 1] && (
                    <div className="info-next-link-wrapper">
                      <Link
                        to={`/article/${
                          articles[articlePostion - 1].node.slug
                        }`}
                      >
                        <span className="next-link-info-wrapper">
                          <span className="next-title">
                            {articles[articlePostion - 1].node.title}
                          </span>
                          <span className="meta-wrapper">
                            <span className="date-post">
                              {format(
                                new Date(
                                  articles[articlePostion - 1].node.published_at
                                ),
                                "dd MMM yyyy"
                              )}
                            </span>
                          </span>
                        </span>

                        <span className="image-next">
                          <img
                            src={
                              articles[articlePostion - 1].node.feature_image
                                ? articles[articlePostion - 1].node
                                    .feature_image.childImageSharp.fluid.src
                                : defaultEventPicture
                            }
                            alt="bb"
                          />
                          <span className="post-nav-title">Next</span>
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ArticleContent
